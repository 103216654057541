import React from "react";
import { graphql, Link } from "gatsby";
import classnames from "classnames";
import Fade from "react-reveal/Fade";

import Layout from "../components/layout";
import SEO from "../components/seo";
import WorkTile from "../components/work-tile";

import richlinksStyle from "../css/richlinks.module.css";

function IndexPage({ data }) {
  const {
    hero_text,
    subhead_one,
    subhead_two,
    home_work,
    work_link,
    work_link_text,
    clients_title,
    clients,
    services_title,
    services,
    cta_title,
    cta_body
  } = data.prismicHome.data;
  const { nodes: work } = data.homeWork;
  return (
    <Layout>
      <SEO
        keywords={[
          `amanda kievet`,
          `web developer`,
          `vermont`,
          `graphic design`
        ]}
        title="Amanda Kievet"
      />
      <h1 className="font-serif text-3xl md:text-5xl lg:text-8xl text-center leading-none max-w-6xl mx-auto mt-24 mb-10">
        {hero_text}
      </h1>
      <div className="text-center">
        <div
          dangerouslySetInnerHTML={{ __html: subhead_one.html }}
          className={richlinksStyle.rt}
        />
        <div
          dangerouslySetInnerHTML={{ __html: subhead_two.html }}
          className={richlinksStyle.rt}
        />
      </div>
      <section className="flex flex-wrap justify-between max-w-6xl w-full mx-auto mt-20">
        {work.map(({ data, uid }, index) => (
          <WorkTile key={index} {...data} uid={uid} />
        ))}
        <div className="text-center w-full">
          <Link to="/work" className="text-lg md:text-2xl">
            {work_link_text} >
          </Link>
        </div>
      </section>
      <Fade>
        <section className="my-16 md:my-24 text-center max-w-5xl mx-auto px-4">
          <h2 className="text-xl md:text-2xl lg:text-3xl leading-snug">
            {clients_title}
          </h2>
          <div className="flex flex-wrap items-center my-10">
            {clients.map(({ logo, link_to_client }, index) => (
              <Link
                to={link_to_client.url}
                target={link_to_client.target}
                key={index}
                className="w-1/2 md:w-1/3 my-4"
              >
                <img
                  src={logo.url}
                  alt={logo.alt}
                  className="mx-auto w-full px-4"
                  style={{ maxWidth: "150px" }}
                />
              </Link>
            ))}
          </div>
        </section>
      </Fade>
      <Fade>
        <section className="text-center bg-gray-100 py-8 md:py-16 px-4">
          <h2 className="font-serif text-3xl md:text-4xl lg:text-5xl">
            {services_title}
          </h2>
          <ul className="flex flex-wrap my-4">
            {services.map(({ service }, index) => (
              <li className="w-full md:w-1/2 lg:w-1/3 pb-2" key={index}>
                {service}
              </li>
            ))}
          </ul>
        </section>
      </Fade>
      <Fade>
        <section className="text-center my-16 px-4">
          <h2 className="text-5xl md:text-7xl font-serif leading-none my-4">
            {cta_title}
          </h2>
          <p
            dangerouslySetInnerHTML={{ __html: cta_body.html }}
            className={richlinksStyle.rt}
          />
        </section>
      </Fade>
    </Layout>
  );
}

export const query = graphql`
  {
    prismicHome {
      data {
        hero_text
        subhead_one {
          html
        }
        subhead_two {
          html
        }
        about_text
        about_link {
          url
        }
        self_portrait {
          url
        }
        home_work {
          image {
            url
            thumbnails {
              full {
                url
              }
            }
          }
          title
          width
          description
        }
        work_link_text
        work_link {
          url
        }
        clients_title
        clients {
          logo {
            url
          }
          link_to_client {
            url
            target
          }
        }
        services_title
        services {
          service
        }
        cta_title
        cta_body {
          html
        }
      }
    }
    homeWork: allPrismicWork(
      filter: { data: { display_on_home: { eq: "true" } } }
      sort: { fields: data___work_date, order: DESC }
    ) {
      nodes {
        data {
          title {
            text
          }
          collection_image {
            fluid(maxWidth: 550) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
        uid
      }
    }
  }
`;
export default IndexPage;
